<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="danger" @click="nuevaRuta">
                            <v-icon>mdi-plus</v-icon>
                           Nueva Ruta
                        </v-btn>
                     <!--   <div>
                            <v-btn class="ma-2" color="primary">
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn outlined class="ma-2">Import</v-btn>
                            <v-btn outlined class="ma-2">Export</v-btn>
                        </div>-->
                    </div>
                </v-card-title>
                <v-card-title>
                 Rutas
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_rutas"
                    item-key="idruta"
                      show-expand
                    class="elevation-1 table-one"
                    multi-sort
                >
               
 

                    <template v-slot:item.action="{item}">

                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="editRuta(item.idruta)"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="eliminarRuta(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                      <!--  <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>-->
                    </template>

                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { url } from "../../main.js";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Rutas'
    },
    data() {
    return {
        idcliente: this.$route.params.idcliente,
        lista_rutas:[],

          search: '',
           // selected: [],
            headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'nombre'
                },
               
                {text: 'Origen', value: 'origen'},
                {text: 'Destino', value: 'destino'},

              


                {text: 'Acción', value: 'action'}
            ],
     
      edit: false,
      permiso: 0,
     
     
    
      lista_dollys: []

    };
  },
 
  mounted() {
   this.verRutas();
  },
  

  methods: {
 verRutas()
 {
 this.axios
        .get(url + "apiClientes.php/rutas/"+this.idcliente)
        .then(respuesta => {
          console.log(respuesta);
          this.lista_rutas = respuesta.data;
          

          console.log(this.lista_rutas);
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });
 },
    nuevaRuta(){
       this.$router.push("/catalogos/ruta/"+this.idcliente+"/0");


    },
  
  

   
    editRuta(idruta) {
             this.$router.push("/catalogos/ruta/"+this.idcliente+"/"+idruta);

    },
     eliminarRuta(ruta) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar la ruta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiDollys.php/delete_dolly", dolly)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("dolly Eliminado");
                this.verdollys();
              }
            });
        }
      });
    },
    

  
    ver_imagen(name) {
      this.axios
        .get(url + "apiOperadores.php/getImageDrive/" + name)
        .then((respuesta) => {
          console.log(respuesta);
          
          var image_data = respuesta.data;
          
          window.open(image_data.webViewLink);
        });
    },

   

    cancelarEdicion() {
      this.edit = false;
      this.limpiarcampos();
    },
  }
}
</script>
